import { ReactElement, useCallback } from 'react';
import { getActivitiesForm } from '../../../../api/requests/interimDegreeStudentActivities';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { InterimDegreeSearchControl } from '../../interfaces/interim-degree-search-controls.interface';
import { DisplayInfo } from '../DisplayInfo/DisplayInfo';

export interface SearchControlLoaderProps<T> {
  studyPlanEnrollmentId: number | string;
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  InterimDegreeSearchControl
>();

export const SearchControlLoaderConsumer = FetchConsumer;

export default function SearchControlLoader({
  studyPlanEnrollmentId,
  children,
}: SearchControlLoaderProps<InterimDegreeSearchControl>) {
  const request = useCallback(() => {
    return getActivitiesForm({ studyPlanEnrollmentId });
  }, [studyPlanEnrollmentId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 401) {
              return <DisplayInfo />;
            }
            if (error.code === 'HTTP_ERROR' && error.status === 404) {
              return <DisplayInfo />;
            }

            return (
              <DisplayError
                insideCard
                body={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                body="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            data,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
