import { ReactElement, useCallback } from 'react';
import { Col } from 'reactstrap';

import { getActivitiesDetails } from '../../../api/requests/interimDegreeStudentActivities';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { InterimDegreeAuthorizedStudentActivitiesDetails } from '../interfaces/interim-degree-authorized-student-activities-details.interface';
import { DisplayInfo } from './DisplayInfo/DisplayInfo';

export interface InterimDegreeStudentActivitiesLoaderProps<T> {
  interimDegreeId: number | string;
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  InterimDegreeAuthorizedStudentActivitiesDetails
>();

export const InterimDegreeStudentActivitiesLoaderConsumer = FetchConsumer;

export default function InterimDegreeStudentActivitiesLoader({
  interimDegreeId,
  children,
}: InterimDegreeStudentActivitiesLoaderProps<InterimDegreeAuthorizedStudentActivitiesDetails>) {
  const request = useCallback(() => {
    return getActivitiesDetails({ interimDegreeId });
  }, [interimDegreeId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 401) {
              return <DisplayInfo />;
            }
            if (error.code === 'HTTP_ERROR' && error.status === 404) {
              return <DisplayInfo />;
            }
            if (error.code === 'HTTP_ERROR' && error.status === 500) {
              return (
                <Col
                  md={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <DisplayError
                    icon="information"
                    title={'No tienes actividades de titulación'}
                    body={'no estas habilitado para este título intermedio.'}
                  />
                </Col>
              );
            }

            return (
              <DisplayError
                insideCard
                body={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                body="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
